import { createContext, PropsWithChildren, useContext, useState } from "react";
import { InteractingDrug } from "../models/interactingDrug";
import { Interactions } from "../models/interactions";

interface PageContextValue {
    page: string;
    antifungal: string;
    searchInteractingDrug: InteractingDrug[];
    searchInteraction: Interactions[];
    setCurrPageFunc: (page: string) => void;
    setCurrAntifungalFunc: (antifungal: string) => void;
    setSearchInteractingDrugFunc: (interactingDrug: InteractingDrug[]) => void;
    setSearchInteractionsFunc: (interaction: Interactions[]) => void;
}

export const PageContext = createContext<PageContextValue | undefined>(undefined);

export function usePageContext() {
    const context = useContext(PageContext);

    if (context === undefined) {
        throw Error('Oops - we do not seem to be inside the provider');
    }

    return context;
}

export function StoreProvider({children}: PropsWithChildren<any>) {
    const [page, setPage] = useState<string>('Antifungals');
    const [antifungal, setAntifungal] = useState<string>('Amphotericin B');
    const [searchInteractingDrug, setSearchInteractingDrug] = useState<InteractingDrug[]> ([]);
    const[ searchInteraction, setSearchInteraction] = useState<Interactions[]>([]);

    function setCurrPageFunc(page: string): void {
        setPage(page);
    }

    function setCurrAntifungalFunc(antifungal: string): void {
        setAntifungal(antifungal);
    }

    function setSearchInteractingDrugFunc(searchInteractingDrug: InteractingDrug[]): void {
        setSearchInteractingDrug(searchInteractingDrug);
    }

    //set the name of the interacting drug when searching for interactions
    function setSearchInteractionsFunc(searchInteraction: Interactions[]): void {
        setSearchInteraction(searchInteraction);
    }

    return (
        <PageContext.Provider value={{
            page, antifungal, searchInteractingDrug, searchInteraction,
            setCurrPageFunc, setCurrAntifungalFunc, setSearchInteractingDrugFunc, setSearchInteractionsFunc
        }}>
            {children}
        </PageContext.Provider>
    )
}