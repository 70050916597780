import axios, { AxiosResponse } from "axios";
import { NewInteraction } from "../models/newInteraction";
import { store } from "../store/configStore";
import { ReferenceReport } from "../models/report";

axios.defaults.baseURL = process.env.REACT_APP_API_URL; 
//axios.defaults.withCredentials = true; //for cookies

const responseBody = (response : AxiosResponse) => response.data;

axios.interceptors.request.use(config => {
    const token = store.getState().account.user?.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`;
    return config
})



const requests = {
    get: (url: string, params?: URLSearchParams) => axios.get(url, {params}).then(responseBody),
    post: (url: string, body?: {}, config?: any) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, data: FormData) => axios.post(url, data, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody),
    putForm: (url: string, data: FormData) => axios.put(url, data, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody)
}

function createFormData(item: any) {
    let formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key]);
    }
    return formData;
}

const antifungalAgent = {
    list: () => requests.get('Antifungal'),
    details: (id: number) => requests.get(`Antifungal/${id}`),
    create: (name: FormData, config: any) => requests.post('Antifungal/Create', name, config),
    remove: (id: number) => requests.delete(`Antifungal/Delete?id=${id}`)
}

const interactingDrugs = {
    list: () => requests.get('InteractingDrug/list'),
    details: (id: number) => requests.get(`InteractingDrug/${id}`),
    page: (params: URLSearchParams) => requests.get('InteractingDrug', params),
    create: (name: FormData, config: any) => requests.post('InteractingDrug/Create', name, config),
    remove: (id: number) => requests.delete(`InteractingDrug/Delete?id=${id}`)

}

const drugInteractions = {
    details: (id: number) => requests.get(`drugInteractions/${id}`),
    getAll: () => requests.get('DrugInteractions'),
    //list: (name: string) => requests.get(`drugInteractions/antifungal/${name}`),
    list: (name: string) => requests.get(`DrugInteractions/antifungal/test/${name}`),
    search: (name: string, params: URLSearchParams) => requests.get(`drugInteractions/search/${name}`, params),
    updateInteraction: (interaction: any) => requests.putForm('drugInteractions', createFormData(interaction)),
    getIndividual: (antifungal: string, interacting: string) => requests.get(`DrugInteractions/search/${antifungal}/${interacting}`),
    create: (data: NewInteraction) => requests.post('DrugInteractions/Create', data)
}

const InfoBox = {
    list: () => requests.get('infoBoxes'),
    details: (id: number) => requests.get(`infoBoxes/${id}`),
    create: (name: FormData, config: any) => requests.post('infoBoxes/Create', name, config),
    remove: (id: number) => requests.delete(`infoBoxes/Delete?id=${id}`)
}

const Account = {
    login: (values: any) => requests.post('account/login', values),
    register: (values: any) => requests.post('account/register', values),
    currentUser: () => requests.get('account/currentUser')
}


const Templates = {
    list: () => requests.get('template'),
    details: (id: number) => requests.get(`template/${id}`),
    create: (name: FormData, config: any) => requests.post('template/Create', name, config),
    remove: (id: number) => requests.delete(`template/Delete?id=${id}`)
}
interface Data {
    interactionId: number,
    referenceUrl: string,
    description: string
}

const DrugReference = {
    list: (InteractionId: number) => requests.get(`DrugInteractionReference?InteractionId=${InteractionId}`),
    create: (data: Data) => requests.post(`DrugInteractionReference/Create?interactionId=${data.interactionId}&referenceUrl=${data.referenceUrl}&description=${data.description}`),
    remove: (id: number) => requests.delete(`DrugInteractionReference/Delete?referenceId=${id}`),
    updateReference: (reference: any) => requests.putForm('DrugInteractionReference', createFormData(reference)),
}

const Users = {
    list: () => requests.get(`User`),
    remove: (id: number) => requests.delete(`User?id=${id}`),
    updateUser: (user: any) => requests.putForm('User', createFormData(user)),
}

const Reports = {
    report: () => requests.get('Report'),
    reportAntifungal: (id: number) => requests.get(`Report/${id}`),
    reportReferences: (id: number) => requests.get(`Report/ref/${id}`)
}

const agent = {
    antifungalAgent,
    interactingDrugs,
    drugInteractions,
    InfoBox,
    Account,
    Templates,
    DrugReference,
    Users,
    Reports
}

export default agent;