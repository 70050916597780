import { Button } from '@mui/material';
import React from 'react';

export const PrintButton = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Button sx={{margin:'20px'}} variant='contained' onClick={handlePrint}>
      Print
    </Button>
  );
};