import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { Interactions } from "../../../models/interactions";

interface Props {
  closeShow: () => void,
  drug: Interactions | undefined
}

export default function ShowInteraction(props: Props) {
  return (
    <Box>
      <Typography variant='h5' color='secondary'> for {props.drug?.antifungal} and {props.drug?.interactingDrug}</Typography>
      <Paper elevation={3}>
        <Typography variant="h5">Severity: <Typography variant="body1">{props.drug?.severityAsString}</Typography></Typography>
        <Divider />
        <Typography variant="h5">Patient info: <Typography variant="body1"><div dangerouslySetInnerHTML={{__html: props.drug!.basicDetailedInformation}} /></Typography></Typography>
        <Divider />
        <Typography variant="h5">Clinician info: <Typography variant="body1"><div dangerouslySetInnerHTML={{__html: props.drug!.proDetailedInformation}} /></Typography></Typography>
        <Divider />
        <Button onClick={props.closeShow}>Close</Button>
      </Paper>
    </Box>
  )
}