import {useEffect, useState, useRef} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, TextField, Typography } from '@mui/material';
import AddTemplateDialog from './template/AddTemplateDialog';
import agent from '../api/agent';
import LoadingComponent from '../layout/LoadingComponent';

interface templateText {
  id: number;
  template: string
}

export default function BasicSelect() {
  const [template, setTemplate] = useState('');
  const [templates, setTemplates] = useState<templateText[]>([]);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState(['']);
  const ref = useRef(null);
  const freeTextRef = useRef(null);
  const [freeText, setFreeText] = useState('');

  function getTemplates() {
      agent.Templates.list()
        .then((response) => {
          setTemplates(response);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
  }
    
  useEffect(() => {
    getTemplates();
  }, [])

  function clearTemplateBox() {
    setText(['']);
  }
  
  
  function clearFreeText() {
    setFreeText('');
  }

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target
    setTemplate(event.target.value as string);
    let newValue = value + '\n\n';
    setText(prevArray => [...prevArray, newValue])

  };

  const handleManualInput = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setFreeText(e.target.value);
  }

  if (loading) return <LoadingComponent message='Loading...' />

  return (
    <>
    <Box sx={{ minWidth: 120}}>
      <FormControl fullWidth>
        <InputLabel id="template-selector">Template</InputLabel>
        <Select
          labelId="template-selector"
          id="template-select"
          value={template}
          label="Template"
          onChange={handleChange}
        >
          {templates.map((message, i) => (
            <MenuItem key={i} value={message.template}>{message.template}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
    <TextField
        id='template-text-area'
        ref={ref}
        aria-label="minimum height"
        multiline={true}
        rows={14}
        style={{ width: '95%', border: '2px solid lightgrey' }}
        value={text.join(' ')}
        onChange={handleManualInput}
    />
    <AddTemplateDialog getTemplates={getTemplates} />
    <Button variant='outlined' fullWidth onClick={clearTemplateBox}>Clear</Button>
    <Typography variant='h5'>Free Text</Typography>
    <TextField
        id='freetext-text-area'
        ref={freeTextRef}
        aria-label="minimum height"
        multiline={true}
        rows={14}
        style={{ width: '95%', border: '2px solid lightgrey' }}
        value={freeText}
        onChange={handleManualInput}
    />
    <Button variant='outlined' fullWidth onClick={clearFreeText}>Clear</Button>
    </>
  );
}