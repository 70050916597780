import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TablePagination, Typography, IconButton, Badge } from "@mui/material";
import { usePageContext } from "../../../context/PageContext";
import { useCallback, useEffect, useState } from "react";
import agent from "../../../api/agent";
import { Interactions } from "../../../models/interactions";
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import LinkIcon from '@mui/icons-material/Link';
import LoadingComponent from "../../../layout/LoadingComponent";
import InteractionsSearch from "./InteractionsSearch";
import EditInteraction from "./EditInteraction";
import ShowInteraction from "./ShowInteraction";
import ShowInteractionReference from "./ShowInteractionReference";


export default function InteractionsAdmin() {
    let { antifungal, searchInteraction } = usePageContext();
    const [interactions, setInteractions] = useState<Interactions[] | null>(null)
    const [loading, setLoading] = useState(false);
    const [selectedDrug, setSelectedDrug] = useState<Interactions | undefined>();
    const [editMode, setEditMode] = useState(false);
    const [showDrug, setShowDrug] = useState(false);
    const [showDrugReference, setShowDrugReference] = useState(false);

    const getInteractions = useCallback(() => {
        setLoading(true)
            agent.drugInteractions.list(antifungal) 
            .then((response) => {
                setInteractions(response)
                console.log(response)
            })
            .catch((error) => console.log(error.title))
            .finally(() => setLoading(false))
    },
    [antifungal],
    )

    useEffect(() => {
        getInteractions();
    }, [getInteractions])

    function setBackground(code: number){
        if(code === 1){
            return '#d1ecf1'
        }
        if(code === 2) {
            return '#d4edda'
        }
        if(code === 3) {
            return '#fff3cd'
        }
        if(code === 4) {
            return '#f8d7da'
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //edit screen
    function handleSelectDrug(drug: Interactions) {
        setSelectedDrug(drug);
        setEditMode(true);
    }

    function closeEdit() {
        setEditMode(false);
        getInteractions();
    }

    //show screen
    function handleSelectShowDrug(drug: Interactions) {
        setSelectedDrug(drug);
        setShowDrug(true);
    }

    function closeShow() {
        setShowDrug(false);
    }

    //reference screen
    function handleSelectShowDrugReference(drug: Interactions) {
        setSelectedDrug(drug);
        setShowDrugReference(true);
    }

    function closeShowReference() {
        setShowDrugReference(false);
        getInteractions();
    }


    

    if(loading) return <LoadingComponent message="Loading interactions" />

    if(editMode) return <EditInteraction closeEdit={closeEdit} drug={selectedDrug} getInteractions={getInteractions}/>

    if(showDrug) return <ShowInteraction closeShow={closeShow} drug={selectedDrug} />

    if(showDrugReference) return <ShowInteractionReference closeShowReference={closeShowReference} drug={selectedDrug} />

  return (
    
    <Paper sx={{ width: '100%' }}>
        <Typography variant='h5' color='secondary'> for {antifungal}</Typography>
        <InteractionsSearch />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead sx={{background: 'lightgrey'}}>
            <TableRow>
                <TableCell sx={{fontSize:'20px'}} align="center">Interacting Drug</TableCell>
                <TableCell sx={{fontSize:'20px'}} align="center">Severity</TableCell>
                <TableCell sx={{fontSize:'20px'}} align="center">Patient Info</TableCell>
                <TableCell sx={{fontSize:'20px'}} align="center">Clinician Info</TableCell>
                <TableCell sx={{fontSize:'20px'}} align="center">Action</TableCell>
            </TableRow>
        </TableHead>
          <TableBody>
            {/* When searching for specific meds */}
            {searchInteraction.length > 0 && searchInteraction?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>{row.interactingDrug}</TableCell>
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>{row.severityAsString}</TableCell>
                        <TableCell align="center" style={{ width: '300px' }} sx={{background: setBackground(row.severity)}}>{row.basicDetailedInformation}</TableCell>
                        <TableCell align="center" style={{ width: '400px' }} sx={{background: setBackground(row.severity)}}>{row.proDetailedInformation}</TableCell>
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>
                            <Button onClick={() => handleSelectShowDrug(row)}>
                                <PreviewIcon fontSize="large" color="success"  />
                            </Button>
                            <Button onClick={() => handleSelectDrug(row)}>
                                <EditIcon fontSize="large"  color="warning"  />
                            </Button>
                            <IconButton 
                                size="large" 
                                sx={{color: 'inherit'}}
                                onClick={() => handleSelectShowDrugReference(row)}
                            >
                               <Badge badgeContent={`${row.drugInteractionReferences.length}`} color='secondary'>
                                    <LinkIcon />
                                </Badge>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
              })}

              {/* showing all interactions */}
              {searchInteraction.length === 0 && interactions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>{row.interactingDrug}</TableCell>
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>{row.severityAsString}</TableCell>
                        <TableCell align="center" style={{ width: '300px' }} sx={{background: setBackground(row.severity)}}>{row.basicDetailedInformation}</TableCell>
                        <TableCell align="center" style={{ width: '400px' }} sx={{background: setBackground(row.severity)}}>{row.proDetailedInformation}</TableCell>
                        <TableCell align="center" sx={{background: setBackground(row.severity)}}>
                            <Button onClick={() => handleSelectShowDrug(row)}>
                                <PreviewIcon fontSize="large" color="success"  />
                            </Button>
                            <Button onClick={() => handleSelectDrug(row)}>
                                <EditIcon fontSize="large"  color="warning"  />
                            </Button>
                            <IconButton onClick={() => handleSelectShowDrugReference(row)} size="large" sx={{color: 'inherit'}}>
                                <Badge badgeContent={row.drugInteractionReferences.length} color='secondary'>
                                    <LinkIcon />
                                </Badge>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {interactions !== null &&
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={searchInteraction.length > 0 ? searchInteraction.length : interactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
    </Paper>
  );
}