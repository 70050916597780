import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AppBar, Tab, Tabs, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import agent from '../api/agent';
import { Antifungal } from '../models/antifungal';
import { usePageContext } from '../context/PageContext';

export default function PageSelector() {
  const [antifungals, setAntifungals] = useState<Antifungal[] | null>(null);
  const { page, antifungal, setCurrPageFunc, setCurrAntifungalFunc, setSearchInteractionsFunc } = usePageContext();

  const handleChangePage = (event: React.SyntheticEvent, newValue: string) => {
    setCurrPageFunc(newValue);
  };

  const handleChangeAntifungal = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    setCurrAntifungalFunc(newValue);
    setSearchInteractionsFunc([]);
  };

  useEffect(() => {
    agent.antifungalAgent.list().then((response) => {
      setAntifungals(response);
    });
  }, []);

  return (
    <>
      <AppBar position="static" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs value={page} onChange={handleChangePage} textColor='inherit'>
          <Tab label="Antifungals" value="Antifungals" />
          <Tab label="Drug Interactions" value="Drug Interactions" />
          <Tab label="Info Boxes" value="Info Boxes" />
          <Tab label="Interacting Drugs" value="Interacting Drugs" />
          <Tab label="Users" value="Users" />
          <Tab label="Reports" value="Reports" />
          {page === 'Drug Interactions' && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <FormControl sx={{ minWidth: '200px' }}>
              <Select
                labelId="template-selector-antifungal"
                id="template-select-antifungal"
                value={antifungal}
                label="Antifungal"
                sx={{
                  color: 'white',
                  '& .MuiInput-underline:before': {
                    borderBottomColor: 'white',
                  },
                }}
                onChange={handleChangeAntifungal}
              >
                {antifungals?.map((antifungal) => (
                  <MenuItem key={antifungal.id} value={antifungal.name}>
                    {antifungal.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          )}
        </Tabs>
      </AppBar>
    </>
  );
}