import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { accountSlice } from "../../features/account/accountSlice";
import { interactingDrugsSlice } from "../../features/admin/interactingDrugs/interactingDrugsSlice";
import { InteractionsSlice } from "../../features/admin/interactions/InteractionsSlice";

export const store = configureStore({
    reducer: {
        interactingDrug: interactingDrugsSlice.reducer,
        account: accountSlice.reducer,
        interactions: InteractionsSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;