import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Badge, Box, Button, Divider, IconButton, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import LoadingComponent from "../../../layout/LoadingComponent";
import { Antifungal } from "../../../models/antifungal";
import { InteractingDrug } from "../../../models/interactingDrug";
import { Interactions } from "../../../models/interactions";
import AddNewInteractionDialog from "./AddNewInteractionDialog";
import Editor from "./Editor";
import LinkIcon from '@mui/icons-material/Link';
import ShowInteractionReference from "./ShowInteractionReference";


interface Props {
  closeEdit: () => void,
  drug: Interactions| undefined,
  getInteractions: () => void
}

export default function EditInteraction({closeEdit, drug, getInteractions}: Props) {
const [interactionDetails, setInteractionDetails] = useState(drug);
const [loading, setLoading] = useState(false);
const [showDrugReference,setShowDrugReference] = useState(false);
const [interactionError, setInteractionError] = useState(false);
const [addInteraction, setAddInteraction] = useState(false);

//references
const [references, setReferences] = useState([])
const [refCount, setRefCount] = useState(0);

//drop downs
const [open, setOpen] = useState(false);
const [interactingDrug, setInteractingDrug] = useState<string | undefined>(interactionDetails?.interactingDrug);
const [interactingDrugs, setInteractingDrugs] = useState<string[]>([]);
const [inputValue, setInputValue] = useState("");


const [openAF, setOpenAF] = useState(false);
const [antifungalDrug, setAntifungalDrug] = useState<string | undefined>(interactionDetails?.antifungal);
const [antifungalDrugs, setAntifungalDrugs] = useState<string[]>([]);
const [inputValueAF, setInputValueAF] = useState("");

//Get list of interacting drugs to fill drop down
useEffect(() => {
  setLoading(true)
  agent.interactingDrugs.list()
  .then((response) => {
    let interactingDrugs: InteractingDrug[] = response;
    let interactingDrugName: string[] = []
    interactingDrugs.forEach(interactingDrug => {
      interactingDrugName.push(interactingDrug.genericName);
    });
    setInteractingDrugs(interactingDrugName)
  })
  .catch(error => console.log(error))
  .finally(() => setLoading(false))
}, [])


//Get list of antifungals to fill drop downs
useEffect(() => {
  setLoading(true)
  agent.antifungalAgent.list()
  .then((response) => {
    let antifungals: Antifungal[] = response;
    let antifungalName: string[] = []
    antifungals.forEach(antifungal => {
      antifungalName.push(antifungal.name);
    });
    setAntifungalDrugs(antifungalName)
  })
  .catch(error => console.log(error))
  .finally(() => setLoading(false))
}, [])

//Get details for a specific interaction between 2 drugs
const getInteractionDetails =  () => {
  setInteractionError(false)
  setLoading(true)
  agent.drugInteractions.getIndividual(antifungalDrug!, interactingDrug!)
  .then((response: Interactions) => {
    console.log(response)
    if(response.severity === undefined) {
      setInteractionError(true)
    } else {
      let data = {...interactionDetails!};
      data = response;
      setInteractionDetails(data);
    }
  })
  .catch(error => {
    console.log(error)
    setInteractionError(true)
  }
    )
  .finally(() => {
    getReferences();
  })
}

const getReferences = () => {
  if(interactionDetails!.id !== undefined) {
    agent.DrugReference.list(interactionDetails!.id)
    .then((response: any) => {
      let refArray = [...references]
      refArray = response;
      setReferences(refArray);
    })
    .catch((error: any) => {
      //console.log(error);
    })
    .finally(() => {
      setLoading(false);
      let newRefCount = refCount
      newRefCount = references.length;
      setRefCount(newRefCount)
    }
    );
  }
  
}

//Change patient info when updated in editor
const handlePatientInfo = (patientInfo: string) => {
    setInteractionDetails({
      ...interactionDetails!,
      basicDetailedInformation: patientInfo
    })
}

//Change clinican details when updated in editor
const handleClinicianInfo = (clinicianInfo: string) => {
  setInteractionDetails({
    ...interactionDetails!,
    proDetailedInformation: clinicianInfo
  })
}

//Change the servity code value
const handleChangeSeverity = (e: SelectChangeEvent) => {
  
  interactionDetails!.severityAsString = e.target.value

  if(e.target.value === 'Unlikely') {
    setInteractionDetails({
      ...interactionDetails!,
      severity: 1
    })
  }
  if(e.target.value === 'Mild') {
    setInteractionDetails({
      ...interactionDetails!,
      severity: 2
    })
  }
  if(e.target.value === 'Moderate') {
    setInteractionDetails({
      ...interactionDetails!,
      severity: 3
    })
  }
  if(e.target.value === 'Severe') {
    setInteractionDetails({
      ...interactionDetails!,
      severity: 4
    })
  }
}

//send updated interaction info to api
const update = () => {
  let updatedInteraction = {}
  if(interactionDetails !== undefined) {
    updatedInteraction = {
      Id: interactionDetails?.id,
      BasicDetailedInformation: interactionDetails.basicDetailedInformation!,
      ProDetailedInformation: interactionDetails.proDetailedInformation!,
      Severity: interactionDetails.severity!
    }
  }
  setLoading(true);
  agent.drugInteractions.updateInteraction(updatedInteraction)
  .then((response) => {
    toast.success('Interaction updated');
  })
  .catch((error) => {
    console.log(error)
    toast.warning(error.reponse)
  })
  .finally(() => {
    setLoading(false)
    getInteractionDetails()
  })
}

function setBackground(code: number){
  if(code === 1){
      return '#d1ecf1'
  }
  if(code === 2) {
      return '#d4edda'
  }
  if(code === 3) {
      return '#fff3cd'
  }
  if(code === 4) {
      return '#f8d7da'
  }
}

function closeShowReference() {
  setShowDrugReference(false);
  getInteractions();
}

//reference screen
function handleSelectShowDrugReference() {
  setShowDrugReference(true);
}

function handleOpenAddInteraction() {
  setAddInteraction(!addInteraction);
}

// useEffect(() => {
//   interactionDetails!.antifungal! = antifungal;
// }, [antifungal, interactionDetails])

if (loading) return <LoadingComponent message='fetching interaction details' />

if(showDrugReference) return <ShowInteractionReference closeShowReference={closeShowReference} drug={interactionDetails} />

if(addInteraction) return <AddNewInteractionDialog getInteractionDetails={getInteractionDetails} setAntifungal={setAntifungalDrug} setInteractingDrug={setInteractingDrug} handleOpenAddInteraction={handleOpenAddInteraction} antifungalDrugs={antifungalDrugs} interactingDrugs={interactingDrugs} />

  return (
    <>
    <Box sx={{background: setBackground(interactionDetails?.severity!)}}>
      <Typography variant='h5' color='secondary'> for {interactionDetails?.antifungal} and {interactionDetails?.interactingDrug}</Typography>
      <Box sx={{display: 'flex', my: '10px'}}>
      <Autocomplete
          value={antifungalDrug}
            open={openAF}
            onChange={(event: any, newValue: string | null) => {
              setAntifungalDrug(newValue!);
            }}
            inputValue={inputValueAF}
            onInputChange={(event, newInputValue) => {
              setInputValueAF(newInputValue);

              if (!newInputValue) {
                setOpenAF(false);
              }
            }}
            onOpen={() => {
                setOpenAF(true);
            }}
            onClose={() => setOpenAF(false)}
            options={antifungalDrugs}
            getOptionLabel={(option) => option}
            sx={{width:'25%'}}
            renderInput={(params) => (
              <TextField {...params} label="Select Antifungal" />
            )}
          /> 
        <Autocomplete
          value={interactingDrug!}
            open={open}
            onChange={(event: any, newValue: string | null) => {
              setInteractingDrug(newValue!);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);

              if (!newInputValue) {
                setOpen(false);
              }
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => setOpen(false)}
            options={interactingDrugs}
            getOptionLabel={(option) => option}
            sx={{width:'25%'}}
            renderInput={(params) => (
              <TextField {...params} label="Select interacting drug" />
            )}
          />
          <Button 
            variant='contained' 
            sx={{mx: '20px'}}
            onClick={getInteractionDetails}>Update interaction details
          </Button>
          <Button onClick={handleOpenAddInteraction}>Add New Interaction</Button>
          {/* <AddNewInteractionDialog antifungalDrugs={antifungalDrugs} interactingDrugs={interactingDrugs} /> */}
        </Box>
        {interactionError &&
          <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">No interaction details exist for this combination. Please create one</Alert>
        </Stack>
        }
        {!interactionError &&
      <Paper elevation={3}>
        <Typography variant="h5">Severity:</Typography>
        <Select
          sx={{background: setBackground(interactionDetails?.severity!)}}
          id="select-severity"
          name="severity-selector"
          value={interactionDetails?.severityAsString}
          label="ages"
          onChange={handleChangeSeverity}
        >
          <MenuItem value='Unlikely'>Unlikely</MenuItem>
          <MenuItem value='Mild'>Mild</MenuItem>
          <MenuItem value='Moderate'>Moderate</MenuItem>
          <MenuItem value='Severe'>Severe</MenuItem>
        </Select>
        <Divider />
        <Typography>ID: {interactionDetails!.id}</Typography>
        <Editor 
          details={interactionDetails!}
          handlePatientInfo={handlePatientInfo} 
          handleClinicianInfo={handleClinicianInfo} 
        />
        <Box sx={{display: "flex", justifyContent: 'space-around'}}>
          <LoadingButton loading={loading} onClick={update}>Update</LoadingButton>
          <Button onClick={closeEdit}>Close</Button>
          <IconButton onClick={() => handleSelectShowDrugReference()} size="large" sx={{color: 'inherit'}}>
              <Badge badgeContent={`${interactionDetails?.drugInteractionReferences.length}`} color='secondary'>
                  <LinkIcon />
              </Badge>
          </IconButton>
        </Box>
      </Paper>}
    </Box>
    
    </>
  )
}