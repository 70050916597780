import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './editor.css';
import { Typography } from "@mui/material";
import { Interactions } from '../../../models/interactions';

interface Props {
  details: Interactions;
  handlePatientInfo: (data: any) => void;
  handleClinicianInfo: (data: any) => void
}

const Editor = ({details, handlePatientInfo, handleClinicianInfo}: Props) => {
  return (
    <>
    <Typography variant="h5">Patient info: </Typography>
    <div className='editor'>
      <CKEditor 
        editor={ClassicEditor} 
        data={details.basicDetailedInformation} 
        onChange={ ( event: any, editor: any ) => {
          const data = editor.getData();
          handlePatientInfo(data)
      } } 
      />
    </div>
    {/* <div className='editor'>
      <CKEditor 
        editor={ClassicEditor} 
        data={details.basicDetailedInformation}
        onChange={ ( event: any, editor: any ) => {
          const data = editor.getData();
          handlePatientInfo(data)
        } } 
      />
    </div> */}
    <Typography variant="h5">Clinician info: </Typography>
    <div className='editor'>
      <CKEditor 
        editor={ClassicEditor} 
        data={details.proDetailedInformation} 
        onChange={ ( event: any, editor: any ) => {
          const data = editor.getData();
          handleClinicianInfo(data)
      } } 
      />
    </div>
    </>
  )
}

export default Editor;