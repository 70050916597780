import { useEffect, useState } from "react";
import { Autocomplete, Box, createTheme, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, ThemeProvider, Typography } from "@mui/material";
import agent from "../api/agent";
import ReactionInfo from "./ReactionInfo";
import photo from './photo.jpg';
import { logEvent } from "../../helpers/analytics";

interface InteractingDrug {
  id: number,
  genericName: string
}

const styles = {
  paperContainer: {
      backgroundImage: `url(${photo})`,
      backgroundSize:'cover'
  }
};



const theme = createTheme();

    theme.typography.h4 = {
    fontSize: '2rem',
    '@media (min-width:800px)': {
        fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
    },
    };


export default function ComboBox() {
  const [open, setOpen] = useState(false);
  const [medication, setMedication] = useState<InteractingDrug | null>(null);
  const [medications, setMedications] = useState<InteractingDrug[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [userType, setUserType] = useState<string>("Patient");

  function handleUserChange(event: any) {
    setUserType(event.target.value);
  }

  useEffect(() => {
    agent.interactingDrugs.list()
    .then((response) => {
      setMedications(response)
    })
    
  }, [])

  function compare( a:InteractingDrug, b: InteractingDrug ) {
    if ( a.genericName < b.genericName ){
      return -1;
    }
    if ( a.genericName > b.genericName ){
      return 1;
    }
    return 0;
  }

  return (
    <>
    <Box style={styles.paperContainer}
    sx={{ 
      minWidth: '100%', 
      height: '500px', 
      paddingLeft: '20px', 
      display:'flex', 
      flexDirection:'column', 
      justifyContent:'center', 
      alignItems:'center',
      backGroundSize:'auto',
      backgroundImage: {photo}
      }
    }>
      <ThemeProvider theme={theme}>
        <Typography variant="h4" color='lightgrey' sx={{paddingBottom:'40px'}}>
          Search an interaction below
        </Typography>
      </ThemeProvider>
    <Autocomplete
    value={medication}
      open={open}
      onChange={(event: any, newValue: InteractingDrug | null) => {
        logEvent('Select Box', 'Interacting Drug Selected', event.target.value)
        setMedication(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);

        if (!newInputValue) {
          setOpen(false);
        }
      }}
      onOpen={() => {
          setOpen(true);
      }}
      onClose={() => setOpen(false)}
      options={medications.sort(compare)}
      getOptionLabel={(option) => option.genericName}
      sx={{width:'75%', backgroundColor:'white'}}
      renderInput={(params) => (
        <TextField {...params} label="Select medication" variant="filled" />
      )}
    />  
    <FormControl >
      <FormLabel id="patient-group-label" sx={{display: 'flex', alignItems:'center', color:'white' }}>I am a </FormLabel>
          <RadioGroup
              row
              defaultValue="Patient"
              aria-labelledby="patient-group-label"
              name="patient-group-select"
          >
              
          <FormControlLabel value="Patient" sx={{color:'white'}} control={<Radio sx={{color:'white'}} size="medium" onChange={handleUserChange} />} label="Patient" />
          <FormControlLabel value="Clinician" sx={{color:'white'}} control={<Radio sx={{color:'white'}} size="medium" onChange={handleUserChange} />} label="Clinician" />
        </RadioGroup>
    </FormControl>
    </Box>
    
    <Box sx={{width: '100%'}}>
            <ReactionInfo 
              id={medication?.id}
              interactingDrug = {medication?.genericName}
              userType={userType}
            />
    </Box>
    </>
  );
  
}