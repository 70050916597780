import { Box, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import agent from "../../../api/agent";
import LoadingComponent from "../../../layout/LoadingComponent";
import { Interactions } from "../../../models/interactions";
import AddReferenceDialog from "./AddReferenceDialog";
import ReferenceEdit from "./ReferenceEdit";

interface Props {
  closeShowReference: () => void,
  drug: Interactions | undefined
}

interface Reference {
  id: number;
  drugInteractionId: number;
  referenceUrl: string;
  description: string;
}

export default function ShowInteractionReference(props: Props) {

  const [references, setReferences] = useState<Reference[]>([])
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);


  const getReferences = useCallback(() => {
    if(props.drug!.id !== undefined) {
      agent.DrugReference.list(props.drug!.id)
    .then((response) => {
      setReferences(response);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => setLoading(false));
    }
    
},
[props.drug],
)

  useEffect(() => {
    getReferences();
  }, [getReferences])

  const handleEditMode = () => {
    setEditMode(!editMode);
  }

  if (loading) return <LoadingComponent message = 'Loading references...' />

  if (references.length === 0) return (
    <Box>
      <Typography sx={{alignText: 'center'}} variant='h5'>No references for this interaction, Click below to add one</Typography>
      {props.drug!.id !== undefined &&
      <AddReferenceDialog Id={props.drug!.id} getReferences={getReferences} />}
      <Button onClick={props.closeShowReference}>Close</Button>
    </Box>
  )

  if (editMode) return <ReferenceEdit references={references} handleEditMode={handleEditMode} getReferences={getReferences} drugInteraction={props.drug}/>
  

  return (
    <>
    <Typography variant='h5'>References for {props.drug?.antifungal} & {props.drug?.interactingDrug}</Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>ID</strong></TableCell>
            <TableCell align="right"><strong>Reference URL</strong></TableCell>
            <TableCell align="right"><strong>Description</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {references.map((referencce) => (
            <TableRow
              key={referencce.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {referencce.id}
              </TableCell>
              <TableCell align="right">{referencce.referenceUrl}</TableCell>
              <TableCell align="right">{referencce.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button onClick={handleEditMode}> Edit</Button>
    {props.drug!.id !== undefined &&
    <AddReferenceDialog Id={props.drug!.id} getReferences={getReferences}/>}
    <Divider />
    <Button onClick={props.closeShowReference}>Close</Button>
    </> 
  )
}