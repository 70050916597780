import { Box, ListItem, useMediaQuery, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function Footer() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const footerLinks = [
        {title: 'About', path: '/about'},
        {title: 'Contact', path: '/contact'},
        {title: 'Terms of use', path: '/terms'},
        {title: 'Disclaimer', path: '/disclaimer'},
        {title: 'Privacy policy', path: '/privacy'}
    ]

    const navStyles = {
        marginTop: '20px',
        backgroundColor: 'white',
        color: 'inherit', 
        textDecoration: 'none',
        typography: 'h6',
        '&:hover': {
            color : 'grey.500'
        },
        '&.active': {
            color: 'text.secondary'
        }
    }
    return (
        <Box display={isMobile ? 'block' : 'flex'} style={navStyles}>
            {footerLinks.map(({title, path}) => (
                <ListItem 
                    component={NavLink}
                    to={path}
                    key={path}
                    sx={navStyles}
                >
                    {title.toUpperCase()}
                </ListItem>
            ))}
        </Box>
    )
}