import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react"
import agent from '../../../api/agent'
import { InteractingDrug, InteractingDrugParams } from "../../../models/interactingDrug";
import DeleteIcon from '@mui/icons-material/Delete';
import InteractionSearch from "./InteractingDrugSearch";
import { usePageContext } from "../../../context/PageContext";
import AddIDDialog from "./AddIDDialog";
import { toast } from "react-toastify";

export default function InteractingDrugAdmin() {
    const [interactingDrugs, setInteractingDrugs] = useState<InteractingDrug[] | null>(null)
    const {searchInteractingDrug} = usePageContext();

    function getAxiosParams(interactionParams: InteractingDrugParams) {
        const params = new URLSearchParams();
        params.append('OrderBy', interactionParams.orderBy);
        params.append('PageNumber', interactionParams.pageNumber.toString());
        params.append('PageSize', interactionParams.pageSize.toString());
        if (interactionParams.searchTerm) params.append('searchTerm', interactionParams.searchTerm);

        return params;
    }
    const getInteractingDrug = useCallback(() => {
            const params = getAxiosParams({'orderBy': 'Name','pageNumber': 1, 'pageSize': 20})
            agent.interactingDrugs.page(params)
            .then((response) => {
                setInteractingDrugs(response)
            })
            .catch((error) => console.log(error))
    },
    [],
    )

    useEffect(() => {
        getInteractingDrug();
    }, [getInteractingDrug])

    function deleteInteractingDrug(id: number) {
        agent.interactingDrugs.remove(id)
        .then((response) => {   
            toast.success(response);
        })
        .catch((error: any) => {
            console.log(error);
            toast.warn(`You can't do that - ${error.response.statusText}`)
        })
        .finally(() => getInteractingDrug())
    }
    
    return (
        <>
        <AddIDDialog getInteractingDrug={getInteractingDrug}/>
        <InteractionSearch />
        <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{fontSize:'20px'}}>Drug ID</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Antifungal</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {searchInteractingDrug.length > 0 && searchInteractingDrug?.map((interactingDrug: InteractingDrug) => (
                        <TableRow
                        key={interactingDrug.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {interactingDrug.id}
                        </TableCell>
                        <TableCell align="center">{interactingDrug.genericName}</TableCell>
                        <TableCell align="center">
                            <Button onClick={() => deleteInteractingDrug(interactingDrug.id)}>
                                <DeleteIcon color="error"  />
                            </Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    {searchInteractingDrug.length === 0 && interactingDrugs?.map((interactingDrug: InteractingDrug) => (
                        <TableRow
                        key={interactingDrug.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {interactingDrug.id}
                        </TableCell>
                        <TableCell align="center">{interactingDrug.genericName}</TableCell>
                        <TableCell align="center">
                            <Button onClick={() => deleteInteractingDrug(interactingDrug.id)}>
                                <DeleteIcon color="error"  />
                            </Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
        
        
    )
}