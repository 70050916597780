import { debounce, TextField } from "@mui/material";
import { useState } from "react";
import agent from "../../../api/agent";
import { usePageContext } from "../../../context/PageContext";
import { InteractingDrugParams } from "../../../models/interactingDrug";

export default function InteractionSearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const {setSearchInteractingDrugFunc} = usePageContext();

    function getAxiosParams(interactionParams: InteractingDrugParams) {
        const params = new URLSearchParams();
        params.append('OrderBy', interactionParams.orderBy);
        params.append('PageNumber', interactionParams.pageNumber.toString());
        params.append('PageSize', interactionParams.pageSize.toString());
        if (interactionParams.searchTerm) params.append('searchTerm', interactionParams.searchTerm);

        
        return params;
    }

    const debouncedSearch = debounce((event: any) => {
        const params = getAxiosParams({'orderBy': "name",'pageNumber': 1, 'pageSize': 50, 'searchTerm': searchTerm});
        agent.interactingDrugs.page(params)
        .then((response) => {
            setSearchInteractingDrugFunc(response)
        })
    }, 2000)


    return (
        <TextField
            label='Search Drugs'
            variant='outlined'
            fullWidth
            value={searchTerm}
            onChange={(event: any) => {
                setSearchTerm(event.target.value);
                debouncedSearch(event);
            }}
        />
    )
}