import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import agent from "../../../app/api/agent";
import { InteractionParams, Interactions } from "../../../app/models/interactions"
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configStore";

interface InteractionsState {
  interactionsLoaded: boolean;
  filtersLoaded: boolean;
  status: string;
  interactionParams: InteractionParams;
  metaData: MetaData | null;
}

const interactionsAdaptor = createEntityAdapter<Interactions>();

// function getAxiosParams(interactionParams: InteractionParams){
//   const params = new URLSearchParams();
//   params.append('pageNumber', interactionParams.pageNumber.toString());
//   params.append('pageSize', interactionParams.pageSize.toString());
//   params.append('orderBy', interactionParams.orderBy);
//   if (interactionParams.searchTerm) params.append('searchTerm', interactionParams.searchTerm);
//   return params;
// }

export const fetchInteractionsAsync = createAsyncThunk<Interactions[], void, {state: RootState}>(
  'interactions/fetchInteractionsAsync',
  async (_, thunkAPI) => {
    //const params = getAxiosParams(thunkAPI.getState().interactions.interactionParams)
    try{
      const response = await agent.drugInteractions.getAll();// to add params to getAll args
      thunkAPI.dispatch(setMetaData(response.metaData));
      return response.items
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error})
    }
  }
)

export const fetchInteractionAsync = createAsyncThunk<Interactions, number>(
  'interactions/fetchInteractionAsync',
  async (interactionId, thunkAPI) => {
    try{
      return await agent.drugInteractions.details(interactionId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error})
    }
  }
)

export const fetchFilters = createAsyncThunk(
  'interactions/fetchFilters',
  async (_, thunkAPI) => {
    try {
      //return await agent.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error});
    }
  }
)

function initParams(){
  return {
    pageNumber: 1,
    pageSize: 10,
    orderBy: 'name'
  }
}

export const InteractionsSlice = createSlice({
  name: 'interactions',
  initialState: interactionsAdaptor.getInitialState<InteractionsState>({
    interactionsLoaded: false,
    filtersLoaded: false,
    status: 'idle',
    interactionParams: initParams(),
    metaData: null
  }),
  reducers: {
    setInteractionParams: (state, action) =>{
      state.interactionsLoaded = false;
      state.interactionParams = {...state.interactionParams, ...action.payload, pageNumber: 1};
    },
    setPageNumber: (state, action) => {
      state.interactionsLoaded = false;
      state.interactionParams = {...state.interactionParams, ...action.payload};
    },
    setMetaData: (state, action) => {
        state.metaData = action.payload;
    },
    resetInteractionParams: (state) => {
        state.interactionParams = initParams();
    },
    setInteraction: (state,action) => {
        interactionsAdaptor.upsertOne(state, action.payload)
        state.interactionsLoaded = false;
    },
    removeInteraction: (state, action) => {
        interactionsAdaptor.removeOne(state, action.payload);
        state.interactionsLoaded = false;
    }
  },
  extraReducers: (builder => {
    builder.addCase(fetchInteractionsAsync.pending, (state) => {
      state.status = 'pendingFetchInteractions';
  });
  builder.addCase(fetchInteractionsAsync.fulfilled, (state, action) => {
      interactionsAdaptor.setAll(state, action.payload);
      state.status = 'idle';
      state.interactionsLoaded = true;
  });
  builder.addCase(fetchInteractionsAsync.rejected, (state, action) => {
      state.status = 'idle';
  });
  builder.addCase(fetchInteractionAsync.pending, (state) => {
      state.status = 'pendingFetchInteraction';
  });
  builder.addCase(fetchInteractionAsync.fulfilled, (state, action) => {
      interactionsAdaptor.upsertOne(state, action.payload);
      state.status = 'idle';
  });
  builder.addCase(fetchInteractionAsync.rejected, (state, action) => {
      state.status = 'idle';
  });
  builder.addCase(fetchFilters.pending, (state) => {
      state.status = 'pendingFetchFilters';
  });
  builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filtersLoaded = true;
      state.status = 'idle';
  });
  builder.addCase(fetchFilters.rejected, (state, action) => {
      state.status = 'idle';
  });
  })
})

export const interactionSelectors = interactionsAdaptor.getSelectors((state: RootState) => state.interactions);
export const { setInteractionParams, resetInteractionParams, setMetaData, setPageNumber, setInteraction, removeInteraction} = InteractionsSlice.actions;