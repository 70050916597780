import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import agent from '../../../api/agent';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  getInteractingDrug: () => void;
}

export default function AddIDDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const [newInteractingDrug, setNewInteractingDrug] = useState('');
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
}

  const { handleSubmit } = useForm({
    mode:'all',
});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmitData() {
    let formData = new FormData();
    formData.append('name', newInteractingDrug);
    agent.interactingDrugs.create(formData, config)
    .then((response) =>  {
      toast.success(response);
    })
    .catch((error) => {
      toast.warn(`You can't do that - ${error.response.data}`)
    })
    .finally(() => props.getInteractingDrug())
    handleClose();
  }

  function handleChangeInteractingDrug( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewInteractingDrug(event.target.value);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add new Interacting Drug
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new interacting drug</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the new interacting drug below.
          </DialogContentText>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Interacting Drug Name"
            type="text"
            fullWidth
            variant="standard"
            value={newInteractingDrug}
            onChange={handleChangeInteractingDrug}
            />
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' color='success' sx={{float:'right'}}>Submit</LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}