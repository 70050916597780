import { Button, debounce, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import agent from "../../../api/agent";
import { usePageContext } from "../../../context/PageContext";
import { InteractingDrugParams } from "../../../models/interactingDrug";

export default function InteractionsSearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const { antifungal, setSearchInteractionsFunc } = usePageContext();

    function getAxiosParams(interactionParams: InteractingDrugParams) {
        const params = new URLSearchParams();
        params.append('OrderBy', interactionParams.orderBy);
        params.append('PageNumber', interactionParams.pageNumber.toString());
        params.append('PageSize', interactionParams.pageSize.toString());
        if (interactionParams.searchTerm) params.append('searchTerm', interactionParams.searchTerm);
        return params;
    }

    const clearSearch = () => {
        const params = getAxiosParams({'orderBy': "interactingDrug.GenericName",'pageNumber': 1, 'pageSize': 50, 'searchTerm': ''});
        agent.drugInteractions.search(antifungal, params)
        .then((response) => {
            setSearchInteractionsFunc(response);
            setSearchTerm('');
        })
    }

    const debouncedSearch = debounce(() => {
        const params = getAxiosParams({'orderBy': "interactingDrug.GenericName",'pageNumber': 1, 'pageSize': 50, 'searchTerm': searchTerm});
        agent.drugInteractions.search(antifungal, params)
        .then((response) => {
            setSearchInteractionsFunc(response);
        })
    }, 1000)

    // useEffect(() => {
    //     debouncedSearch();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchTerm])

    
    return (
        <>
            <TextField
                label='Search Drugs'
                variant='outlined'
                fullWidth
                value={searchTerm !== '' ? searchTerm : ''}
                onChange={(event: any) => {
                    setSearchTerm(event.target.value);
                }}
            />
            <Box sx={{display:'flex', justifyContent:'space-around'}}>    
                <Button variant='contained' onClick={debouncedSearch}>Search</Button>
                <Button variant='contained' onClick={clearSearch}>Clear</Button>
            </Box>
            
        </>
        
    )
}