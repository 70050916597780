import {useState} from 'react';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import agent from '../../../api/agent';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  Id: number;
  getReferences: () => void;
}

interface Data {
  interactionId: number;
  referenceUrl: string;
  description: string;
}

export default function AddReferenceDialog(props: Props) {
  const [newDescription, setNewDescription] = useState('');
  const [newUrl, setNewUrl] = useState('');
//   const config = {     
//     headers: { 'content-type': 'multipart/form-data' }
// }

  const { handleSubmit } = useForm({
    mode:'all',
});

  async function handleSubmitData() {
    let formData = new FormData();
    const valid = validUrlChecker(newUrl);
    if (valid) {
      formData.append('description', newDescription);
      formData.append('interactionId', props.Id.toString());
      formData.append('referenceUrl', newUrl);
      const data: Data = {
        interactionId: props.Id,
        referenceUrl: newUrl,
        description: newDescription
      }

      agent.DrugReference.create(data)
      .then((response) => {
        console.log(response)
        toast.success('Reference added')
      })
      .catch((error) => console.log(error))
      .finally(() => {
        props.getReferences();
      })
    } else {
      toast.error('Please ensure url is in a valid format')
    }
    
  }

  function handleChangeUrl( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewUrl(event.target.value);
  }

  function handleChangeDescription( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewDescription(event.target.value);
  }

  function validUrlChecker(url: string) {
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
    return !!urlPattern.test(url);
  }

  return (
    <div>
        <DialogTitle>Add new references</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the reference details below
          </DialogContentText>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
            autoFocus
            margin="dense"
            id="URL"
            name="url"
            label="Reference URL"
            type="text"
            fullWidth
            variant="standard"
            value={newUrl}
            onChange={handleChangeUrl}
            />
            <TextField
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard" 
            value={newDescription}
            onChange={handleChangeDescription}
            />
            <LoadingButton type='submit' variant='contained' color='success' sx={{float:'center'}}>Submit</LoadingButton>
          </form>
        </DialogContent>
    </div>
  );
}