import { AppBar, Box, createTheme, ThemeProvider, Toolbar, Typography } from "@mui/material";
import fit from './fit.png'
import nac from './nac.jpg';

export default function Header() {

    const theme = createTheme();

    theme.typography.h4 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
    };
    
    return (
        <AppBar position='static' sx={{mb: 4}}>
            <Toolbar sx={{display:'flex', justifyContent: 'space-around'}}>
                <Box display='flex' alignItems='center'>
                        <img src={nac} style={{width:'100px'}} alt="National Aspergillosis Centre" />
                        <img src={fit} style={{width:'100px'}} alt="fungal infection trust" />
                        <ThemeProvider theme={theme}>
                            <Typography 
                                variant='h4' 
                                ml={3}
                                color='white'>
                                Antifungal Interactions Database
                            </Typography>
                    </ThemeProvider>
                    
                </Box>
            </Toolbar>
        </AppBar>
    )
}