import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import agent from '../../../api/agent';
import AddIBDialog from './AddIBDialog';
import { InfoBoxes } from '../../../models/infoBoxes';
import { LoadingButton } from '@mui/lab';

export default function InfoBoxesAdmin() {
    const [infoBoxes, setInfoBoxes] = useState<InfoBoxes[] | null>(null);
    const [loading, setLoading] = useState(false);

    function getInfoBoxes() {
        agent.InfoBox.list()
        .then((response) => {
            setInfoBoxes(response)
        })
        .catch((error) => console.log(error))
    }
    
    useEffect(() => {
      getInfoBoxes();
    }, [])

    function deleteInfoBox(id: number) {
        setLoading(true);
        agent.InfoBox.remove(id)
        .then((response) => {   
            toast.success(response);
        })
        .catch((error: any) => {
            console.log(error);
            toast.warn(`You can't do that - ${error.response.statusText}`)
        })
        
        .finally(() => {
            getInfoBoxes();
            setLoading(false);
        });
    }
    

    return (
        <>
            <AddIBDialog getInfoBoxes={getInfoBoxes} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{fontSize:'20px'}}>ID</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Message</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {infoBoxes?.map((infoBox: InfoBoxes) => (
                        <TableRow
                        key={infoBox.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {infoBox.id}
                        </TableCell>
                        <TableCell align="center">{infoBox.message}</TableCell>
                        <TableCell align="center">
                            <LoadingButton loading={loading} onClick={() => deleteInfoBox(infoBox.id)}>
                                <DeleteIcon color="error"  />
                            </LoadingButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}