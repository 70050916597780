import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/agent";
import { InteractingDrug, InteractingDrugParams } from "../../../app/models/interactingDrug";
import { MetaData } from "../../../app/models/pagination";
import { RootState } from "../../../app/store/configStore";

interface InteractingDrugState {
    drugsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    interactingDrugParams: InteractingDrugParams;
    metaData: MetaData | null;
}

const interactingDrugsAdapter = createEntityAdapter<InteractingDrug>();

// function getAxiosParams(interactionParams: InteractingDrugParams) {
//     const params = new URLSearchParams();
//     params.append('pageNumber', interactionParams.pageNumber.toString());
//     params.append('pageSize', interactionParams.pageSize.toString());
//     params.append('orderBy', interactionParams.orderBy);
//     if (interactionParams.searchTerm) params.append('searchTerm', interactionParams.searchTerm);
//     return params;
// }

export const fetchInteractingDrugsAsync = createAsyncThunk<InteractingDrug[], void, {state: RootState}>(
    'interactingDrugs/fetchInteractingDrugsAsync',
    //Underscore argument is equivelent to nothing or void, thunkAPI must be the second arg
    async (_, thunkAPI) => {
        //const params = getAxiosParams(thunkAPI.getState().interactingDrug.interactingDrugParams)
        try {
            const response = await agent.interactingDrugs.list();
            thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
    } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 30,
        orderBy: 'name',
    }
}

export const interactingDrugsSlice = createSlice({
    name: 'interactingDrugs',
    initialState: interactingDrugsAdapter.getInitialState<InteractingDrugState>({
        drugsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        interactingDrugParams: initParams(),
        metaData: null
    }),
    reducers: {
        setInteractingDrugParams: (state, action) => {
            state.drugsLoaded = false;
            state.interactingDrugParams = {...state.interactingDrugParams, ...action.payload, pageNumber: 1};
        },
        setPageNumber: (state, action) => {
            state.drugsLoaded = false;
            state.interactingDrugParams = {...state.interactingDrugParams, ...action.payload};
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetInteractingDrugParams: (state) => {
            state.interactingDrugParams = initParams();
        },
        setInteractingDrug: (state,action) => {
            interactingDrugsAdapter.upsertOne(state, action.payload);
            state.drugsLoaded = false;
        },
        removeInteractingDrug: (state, action) => {
            interactingDrugsAdapter.removeOne(state, action.payload);
            state.drugsLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchInteractingDrugsAsync.pending, (state) => {
            state.status = 'pendingFetchInteractingDrugs';
        });
        builder.addCase(fetchInteractingDrugsAsync.fulfilled, (state, action) => {
            interactingDrugsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.drugsLoaded = true;
        });
        builder.addCase(fetchInteractingDrugsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
    })
})

export const interactingDrugSelectors = interactingDrugsAdapter.getSelectors((state: RootState) => state.interactingDrug);
export const {setInteractingDrugParams, resetInteractingDrugParams, setMetaData, setPageNumber, setInteractingDrug, removeInteractingDrug} = interactingDrugsSlice.actions;