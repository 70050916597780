import { LoadingButton } from "@mui/lab";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import agent from "../../../api/agent";
import LoadingComponent from "../../../layout/LoadingComponent";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import SendIcon from '@mui/icons-material/Send';

interface User {
  userName: string;
  email: string;
  id: number;
  roles?: string[];
}

export default function Users() {
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);

  function getUsers() {
    agent.Users.list()
    .then((response) => {
      setUsers(response);
      console.log(response)
    })
    .catch((error) => console.log(error))
    .finally(() => {
      setLoading(false);
    }
    )
  }

  const removeTestUserAccounts =() => {
    for(let i = 0; i < users.length; i++){
      if(users[i].userName.includes('test')){
        users.splice(i, 1)
        i--
      }
    }
  }

  useEffect(() => {
    getUsers();
  }, [])

  removeTestUserAccounts();

  function deleteUser(id: number) {
    setLoading(true);
    agent.Users.remove(id)
    .then((response) => {   
        toast.success(response);
    })
    .catch((error: any) => {
        console.log(error);
        toast.warn(`You can't do that - ${error.response.statusText}`)
    })
    
    .finally(() => {
        getUsers();
        setLoading(false);
    });
  }

  function handleEdit(){
    setEdit(!edit)
  }

  function onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    if(e.target.id === 'userName') {
      let userArray = [...users];
      let currUser = {...userArray[index]};
      currUser.userName = e.target.value;
      userArray[index] = currUser;
      setUsers(userArray);
    }

    if(e.target.id === 'email') {
      let userArray = [...users];
      let currUser = {...userArray[index]};
      currUser.email = e.target.value;
      userArray[index] = currUser;
      setUsers(userArray);
    }
  }

  function updateUser(id: number, index: number) {
    setLoading(true);
    agent.Users.updateUser(users[index])
    .then((response) => toast.success(response))
    .catch((error) => toast.error(error))
    .finally(()=> {
      setLoading(false);
      getUsers();
    })
  }


  if(loading) return <LoadingComponent message="Loading users" />
  
  return (
    <>
    <Typography variant='h5'>{edit ? 'Edit Users' : ''}</Typography>
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <TableRow>
                  <TableCell sx={{fontSize:'20px'}} align="center">Username</TableCell>
                  <TableCell sx={{fontSize:'20px'}} align="center">Email</TableCell>
                  <TableCell sx={{fontSize:'20px'}} align="center">Roles</TableCell>
                  {edit &&
                  <TableCell sx={{fontSize:'20px'}} align="center">Action</TableCell>}
              </TableRow>
              </TableHead>
              <TableBody>
                {/* not in edit mode */}
              {!edit && users?.map((user: User) => (
                  <TableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell align="center">{user.userName}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.roles?.join(', ')}</TableCell>
                  {edit &&
                  <TableCell align="center">
                      <LoadingButton 
                        loading={loading} 
                        onClick={() => deleteUser(user.id)}
                      >
                          <DeleteIcon color="error"  />
                      </LoadingButton>
                  </TableCell>}
                  </TableRow>
              ))}

              {/* In edit mode */}
              {edit && users?.map((user: User, i) => (
                  <TableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell align="center"><TextField id='userName' onChange={(event) => onChange(event, i)} value={user.userName} fullWidth>{user.userName}</TextField></TableCell>
                  <TableCell align="center"><TextField id='email' onChange={(event) => onChange(event, i)} value={user.email} fullWidth>{user.email}</TextField></TableCell>
                  <TableCell align="center"><TextField id='email' onChange={(event) => onChange(event, i)} value={user.roles} fullWidth>{user.roles}</TextField></TableCell>
                  {edit &&
                  <TableCell align="center">
                      <LoadingButton 
                        loading={loading} 
                        onClick={() => deleteUser(user.id)}
                      >
                          <DeleteIcon color="error"  />
                      </LoadingButton>
                      <LoadingButton 
                        loading={loading} 
                        onClick={() => updateUser(user.id, i)}
                      >
                          <SendIcon color="success"  />
                      </LoadingButton>
                  </TableCell>}
                  </TableRow>
              ))}
              </TableBody>
          </Table>
      </TableContainer>
      <Button variant='outlined' onClick={handleEdit} sx={{mt:3}}>{edit ? 'Close' : 'Edit'} </Button>
    </>
    
  )
}