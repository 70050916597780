import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Editor from './Editor';
import { Interactions } from '../../../models/interactions';
import { toast } from 'react-toastify';
import agent from '../../../api/agent';
import { Antifungal } from '../../../models/antifungal';
import { InteractingDrug } from '../../../models/interactingDrug';
import { NewInteraction } from '../../../models/newInteraction';

interface Props {
  interactingDrugs: string[];
  antifungalDrugs: string[];
  handleOpenAddInteraction: () => void,
  setAntifungal: Dispatch<SetStateAction<string | undefined>>,
  setInteractingDrug: Dispatch<SetStateAction<string | undefined>>,
  getInteractionDetails: () => void
}

interface newReference {
  newUrl: string;
  newDescription: string;
}


export default function AddNewInteractionDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const [antifungals, setAntifungals] = useState<Antifungal[] | undefined>()
  const [interactingDrugs, setInteractingDrugs] = useState<InteractingDrug[] | undefined>()
  const [newReference, setNewReference] = useState<newReference>({
    newUrl: '',
    newDescription: ''
  });

  

//drop downs
const [openID, setOpenID] = useState(false);


const [inputValue, setInputValue] = useState("");

const [openAF, setOpenAF] = useState(false);
const [severityAsString, setSeverityAsString] = useState('Unlikely');

const [inputValueAF, setInputValueAF] = useState("");

//interaction details
const [interactionDetails, setInteractionsDetails] = useState<Interactions>({
  basicDetailedInformation: '',
  proDetailedInformation: '',
  severity: 1,
  severityAsString: 'Unlikely',
  antifungal: '',
  interactingDrug: '',
  drugInteractionReferences: [{
    id: 0,
    description: '',
    referenceUrl: '',
    drugInteractionId: 0
  }]

})

//get Antifungals details and interacting Drug details
useEffect(() => {
  agent.antifungalAgent.list()
  .then((response) => {
    setAntifungals(response)
  })
  .catch((error) => console.log(error))
}, [])

useEffect(() => {
  agent.interactingDrugs.list()
  .then((response) => {
    setInteractingDrugs(response)
  })
  .catch((error) => console.log(error))
}, [])


const handlePatientInfo = (patientInfo: string) => {
  setInteractionsDetails({
    ...interactionDetails,
    basicDetailedInformation: patientInfo
  })
}

const handleClinicianInfo = (clinicianInfo: string) => {
  setInteractionsDetails({
    ...interactionDetails,
    proDetailedInformation: clinicianInfo
  })
}

  function handleChangeUrl( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewReference({
      ...newReference,
      newUrl: event.target.value
    })
  }

  function handleChangeDescription( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewReference({
      ...newReference,
      newDescription: event.target.value
    })
  }

  function handleSubmitData() {
    let currAf = antifungals?.filter(ant => ant.name === interactionDetails.antifungal)
    let currId = interactingDrugs?.filter(ant => ant.genericName === interactionDetails.interactingDrug)
    let newInteraction: NewInteraction = ({
      basicDetailedInformation: interactionDetails.basicDetailedInformation,
      proDetailedInformation: interactionDetails.proDetailedInformation,
      severity: interactionDetails.severity,
      severityAsString: interactionDetails.severityAsString,
      antifungalAgentId: currAf![0].id,
      interactingDrugId: currId![0].id,
      newDescription: newReference.newDescription,
      newUrl: newReference.newUrl
    })
    console.table(newInteraction)
    setLoading(true);
    agent.drugInteractions.create(newInteraction)
    .then((response) =>  {
      console.log(response)
      toast.success('Interaction Created');
      props.handleOpenAddInteraction();
    })
    .catch((error: any) => {
      console.log(error.response)
      toast.warn(`An interaction already exists for this combination of medicines`);
    })
    .finally(() => {
      setLoading(false);
      props.handleOpenAddInteraction();
      props.setAntifungal(interactionDetails.antifungal);
      props.setInteractingDrug(interactionDetails.interactingDrug);
      props.getInteractionDetails();
    })
  }

  const handleChangeSeverity = (e: SelectChangeEvent) => {
    setSeverityAsString(e.target.value as string)
    setInteractionsDetails({
      ...interactionDetails,
      severityAsString: e.target.value as string
    })
  
    if(e.target.value === 'Unlikely') {
      setInteractionsDetails({
        ...interactionDetails,
        severity: 1
      })
    }
    if(e.target.value === 'Mild') {
      setInteractionsDetails({
        ...interactionDetails,
        severity: 2
      })
    }
    if(e.target.value === 'Moderate') {
      setInteractionsDetails({
        ...interactionDetails,
        severity: 3
      })
    }
    if(e.target.value === 'Severe') {
      setInteractionsDetails({
        ...interactionDetails,
        severity: 4
      })
    }
  }

  return (
    <div>
        <DialogTitle>Add new Interaction</DialogTitle> 
        <DialogContent>
          <DialogContentText>
            Enter the interaction details below
          </DialogContentText>
          <Autocomplete
          value={interactionDetails.antifungal}
            open={openAF}
            onChange={(event: any, newValue: string | null) => {
              setInteractionsDetails({
                ...interactionDetails,
                antifungal: newValue!
              });
            }}
            inputValue={inputValueAF}
            onInputChange={(event, newInputValue) => {
              setInputValueAF(newInputValue);

              if (!newInputValue) {
                setOpenAF(false);
              }
            }}
            onOpen={() => {
                setOpenAF(true);
            }}
            onClose={() => setOpenAF(false)}
            options={props.antifungalDrugs}
            getOptionLabel={(option) => option}
            sx={{width:'80%', margin: 'auto', marginBottom: '10px'}}
            renderInput={(params) => (
              <TextField {...params} label="Select Antifungal" />
            )}
          /> 
         <Autocomplete
          value={interactionDetails.interactingDrug}
            open={openID}
            onChange={(event: any, newValue: string | null) => {
              setInteractionsDetails({
                ...interactionDetails,
                interactingDrug: newValue!
              });
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);

              if (!newInputValue) {
                setOpenID(false);
              }
            }}
            onOpen={() => {
                setOpenID(true);
            }}
            onClose={() => setOpenID(false)}
            options={props.interactingDrugs}
            getOptionLabel={(option) => option}
            sx={{width:'80%', margin: 'auto'}}
            renderInput={(params) => (
              <TextField {...params} label="Select Interacting Drug" />
            )}
          /> 
          <Typography variant='body1'>Severity:</Typography>
          <Select
          id="select-severity"
          name="severity-selector"
          value={severityAsString}
          onChange={handleChangeSeverity}
        >
          <MenuItem value='Unlikely'>Unlikely</MenuItem>
          <MenuItem value='Mild'>Mild</MenuItem>
          <MenuItem value='Moderate'>Moderate</MenuItem>
          <MenuItem value='Severe'>Severe</MenuItem>
        </Select>
            <Editor 
              details={interactionDetails}
              handlePatientInfo={handlePatientInfo} 
              handleClinicianInfo={handleClinicianInfo} 
            />
            <Typography variant='h4'>References</Typography>
            <TextField
            autoFocus
            margin="dense"
            id="URL"
            name="url"
            label="Reference URL"
            type="text"
            fullWidth
            variant="standard"
            value={newReference.newUrl}
            onChange={handleChangeUrl}
            />
            <TextField
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard" 
            value={newReference.newDescription}
            onChange={handleChangeDescription}
            />
            <Button onClick={props.handleOpenAddInteraction}>Cancel</Button>
            <LoadingButton loading={loading} onClick={handleSubmitData} variant='contained' color='success' sx={{float:'right'}}>Submit</LoadingButton>
            
        </DialogContent>
    </div>
  );
}