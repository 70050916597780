import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import agent from '../../../api/agent';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  getInfoBoxes: () => void;
}

export default function AddIBDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newInfoBox, setNewInfoBox] = useState('');
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
}

  const { handleSubmit } = useForm({
    mode:'all',
});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmitData() {
    setLoading(true);
    let formData = new FormData();
    formData.append('message', newInfoBox);
    agent.InfoBox.create(formData, config)
    .then((response) =>  {
      toast.success(response);
      setNewInfoBox('');
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      props.getInfoBoxes();
      setLoading(false);
    })
    handleClose();
  }

  function handleChangeInfoBox( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewInfoBox(event.target.value);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add new Info Box
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new Info Box</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new info box below
          </DialogContentText>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Info message"
            type="text"
            fullWidth
            variant="standard"
            value={newInfoBox}
            onChange={handleChangeInfoBox}
            />
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' loading={loading} color='success' sx={{float:'right'}}>Submit</LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}