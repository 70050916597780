import { Grid } from "@mui/material";
import ComboBox from "../../app/components/ComboBox";


export default function Home() {
    return (
        <Grid container>
            <ComboBox />
        </Grid>
    )
}