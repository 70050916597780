import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel, MenuItem, Select, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../../api/agent";
import LoadingComponent from "../../../layout/LoadingComponent";
import { Antifungal } from "../../../models/antifungal";
import { ReferenceReport, Report } from "../../../models/report";
import { InteractingDrug } from "../../../models/interactingDrug";
import { PageContext, usePageContext } from "../../../context/PageContext";
import PreviewIcon from '@mui/icons-material/Preview';
import { ExportReactCSV } from "./excelDownload";

enum Severity {
    Unlikely = 1,
    Mild = 2,
    Moderate = 3,
    Severe = 4,
    Unknown = 5
}

export default function Reports() {
    const { setCurrPageFunc, setCurrAntifungalFunc } = usePageContext();
    const [ antifungals, setAntifungals ] = useState<Antifungal[]>();
    const [ antifungal, setAntifungal ] = useState<Antifungal>();
    const [ report, setReport ] = useState<Report[]>();
    const [ referenceReport, setReferenceReport ] = useState<ReferenceReport[]>();
    const [loading, setLoading] = useState(false);

    const getReportDetails = () => {
        agent.Reports.report()
        .then((response) => {
            setReport(response)
        })
    }
    useEffect(() => {
        agent.antifungalAgent.list()
        .then((response) => {
            setAntifungals(response);
        })
        getReportDetails();
        if (antifungals && antifungals.length > 0) {
            setAntifungal(antifungals[0])
        }
        
    }, [])  
      
    const severityNames = {
        [Severity.Unlikely]: 'Unlikely',
        [Severity.Mild]: 'Mild',
        [Severity.Moderate]: 'Moderate',
        [Severity.Severe]: 'Severe',
        [Severity.Unknown]: 'Unknown'
    };

    const convertSeverity = (severity: Severity): string => {
        return severityNames[severity] || 'Unknown';
    };

    const goToReference = (id: number, interactingDrug: InteractingDrug): void => {
        if (antifungal) {
            setCurrAntifungalFunc(antifungal.name);
            setCurrPageFunc('Drug Interactions')
        }
    }



    const fetchAntifungalReport = (id: number | string): void => {
        if (typeof id === 'number') {
            setLoading(true);
            agent.Reports.reportAntifungal(id)
            .then((response) => {
                setReport(response);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
        }
    }

    const fetchReferenceReport = (id: number | string): void => {
        if (typeof id === 'number') {
            setLoading(true);
            agent.Reports.reportReferences(id)
            .then((response) => {
                setReferenceReport(response);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
        }
    }

    function setBackground(code: number){
        if(code === Severity.Unlikely){
            return '#d1ecf1'
        }
        if(code === Severity.Mild) {
            return '#d4edda'
        }
        if(code === Severity.Moderate) {
            return '#fff3cd'
        }
        if(code === Severity.Severe) {
            return '#f8d7da'
        }
    }
    if (loading) return <LoadingComponent message='Loading...' />
    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                Interaction Report
            </Typography>
            <FormControl sx={{minWidth: '200px'}}>
                <InputLabel id="template-selector-antifungal-report">Antifungals</InputLabel>
                <Select
                    labelId="template-selector-antifungal-report"
                    id="template-select-antifungal-report"
                    value={antifungal?.id}
                    label="Antifungal"
                    onChange={(event) => {
                        const selectedAntifungal = antifungals?.find((antifungal) => antifungal.id === event.target.value);
                        setAntifungal(selectedAntifungal);
                        fetchAntifungalReport(event.target.value);
                    }}
                >
           {antifungals?.map((antifungal) => {
            return (
                <MenuItem key={antifungal.id} value={antifungal.id}>{antifungal.name}</MenuItem>
                    )
                    })}
                </Select>
            </FormControl>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="report table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight:"bolder" }}>Severity</TableCell>
                        <TableCell sx={{ fontWeight:'bolder' }} align="center">Interaction Count</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {report && report.map((row) => (
                        <TableRow
                        key={row.severity}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: setBackground(row.severity) }}
                        >
                            <TableCell component="th" scope="row">
                                {convertSeverity(row.severity)}
                            </TableCell>
                            <TableCell align="center">{row.count}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Total
                        </TableCell>
                        <TableCell align="center">
                            {report?.reduce((acc, curr) => {
                                return acc + curr.count;
                            }, 0)}
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h4" component="h1" gutterBottom>
                Reference Report
            </Typography>
            <FormControl sx={{minWidth: '200px'}}>
                <InputLabel id="template-selector-antifungal-report">Antifungals</InputLabel>
                <Select
                    labelId="template-selector-antifungal-report"
                    id="template-select-antifungal-report"
                    value={antifungal?.id}
                    label="Antifungal"
                    onChange={(event) => {
                        const selectedAntifungal = antifungals?.find((antifungal) => antifungal.id === event.target.value);
                        setAntifungal(selectedAntifungal);
                        fetchReferenceReport(event.target.value);
                    }}
                >
           {antifungals?.map((antifungal) => {
            return (
                <MenuItem key={antifungal.id} value={antifungal.id}>{antifungal.name}</MenuItem>
                    )
                    })}
                </Select>

            </FormControl>
            {Array.isArray(referenceReport) && (
                <ExportReactCSV csvData={referenceReport} fileName={`${antifungal?.name}-Reference-report.csv`} />
                )}
            <Typography variant="h6" gutterBottom>
                Showing total of {referenceReport?.length} references for {antifungal?.name}
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="report table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight:"bolder" }}>Interacting Drug</TableCell>
                        <TableCell sx={{ fontWeight:"bolder" }}>Description</TableCell>
                        <TableCell sx={{ fontWeight:'bolder' }} align="center">URL</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {referenceReport && referenceReport.map((row, index) => (
                        <TableRow
                        key={row.id}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: index % 2 === 0 ? 'lightgrey' : 'inherit',
                          }}
                        >
                            <TableCell component="th" scope="row">
                                {row.interactingDrug.genericName}
                                <Button onClick={() => goToReference(row.id, row.interactingDrug)}>
                                    <PreviewIcon fontSize="small"  color="info"  />
                                </Button>
                            </TableCell>
                            <TableCell align="center">{row.description}</TableCell>
                            <TableCell align="center">
                                <a href={row.url} target="_blank" rel="noopener noreferrer">
                                    {row.url}
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}