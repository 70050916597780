import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { Interactions } from "../../../models/interactions";
import { FieldValues } from "react-hook-form";

interface Reference {
  id: number;
  drugInteractionId: number;
  referenceUrl: string;
  description: string;
}


interface Props {
  references: Reference[];
  handleEditMode: () => void;
  getReferences: () => void;
  drugInteraction: Interactions | undefined;
}



export default function ReferenceEdit(props: Props)  {
  const [references, setReferences] = useState(props.references);
  const [loading, setLoading] = useState(false);

  const getReferences= () => {
    if(props.drugInteraction!.id !== undefined) {
      agent.DrugReference.list(props.drugInteraction!.id)
    .then((response) => {
      setReferences(response);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => setLoading(false));
    }
}
  async function handleSubmitData(data: FieldValues) {
    try {
        await agent.DrugReference.updateReference(data)
        .then((response) => {
          toast.success('Reference updated');
          props.handleEditMode();
        })
    } catch (error: any) {
        console.log(error)
        toast.error('Unable to update');
    }
}

  const handleDelete = (reference: Reference) => {
    setLoading(true)
    agent.DrugReference.remove(reference.id)
    .then((response) => toast.success(response))
    .catch((error) => toast.error(error))
    .finally(() => {
      setLoading(false);
      getReferences();
      props.getReferences();
    })
  }


  const onChange = ( event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number ) => {
    const { name, value } = event.target
    if(name === 'desc') {
      for (let i = 0; i < references.length; i++) {
        if(references[i].id === id){
          let newReference = [...references];
          newReference[i].description = value;
          setReferences(newReference)
        }
      }
    }
    if(name === 'url') {
      for (let i = 0; i < references.length; i++) {
        if(references[i].id === id){
          let newReference = [...references];
          newReference[i].referenceUrl = value;
          setReferences(newReference)
        }
      }
    }
  }
  return (
    <>
    <Typography variant='h5'>References for {props.drugInteraction?.antifungal} & {props.drugInteraction?.interactingDrug}</Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="reference-table">
        <TableHead>
          <TableRow>
            <TableCell><strong>ID</strong></TableCell>
            <TableCell align="center"><strong>Reference URL</strong></TableCell>
            <TableCell align="center"><strong>Description</strong></TableCell>
            <TableCell align="center"><strong>Action</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {references.map((reference) => (
            <TableRow
              key={reference.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {reference.id}
              </TableCell>
              <TableCell align="right">
                <TextField 
                  name="url" 
                  value={reference.referenceUrl}  
                  fullWidth 
                  onChange={event => onChange(event, reference.id)}>{reference.referenceUrl}
                </TextField>
              </TableCell>
              <TableCell align="right"><TextField name="desc" value={reference.description} fullWidth multiline={true} rows={3} onChange={event => onChange(event, reference.id)}>{reference.description}</TextField></TableCell>
              <TableCell align="center">
                  <LoadingButton loading={loading} onClick={() => handleDelete(reference)}>
                      <DeleteIcon color="error"  />
                  </LoadingButton>
                  <LoadingButton loading={loading} onClick={() => handleSubmitData(reference)}>
                      <SendIcon color="success"  />
                  </LoadingButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button onClick={props.handleEditMode}>Close</Button>
    </>
  )
}