import { Container, CssBaseline } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import LoadingComponent from './LoadingComponent';
import { Route, Switch } from 'react-router-dom'
import Home from '../../features/home/Home';
import Footer from './Footer';
import Header from "./Header";
import Quotes from "../components/Quotes";
import Admin from "../../features/admin/Admin";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from "./PrivateRoute";
import Login from "../../features/account/Login";
import Register from "../../features/account/Register";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import { useAppDispatch } from "../store/configStore";

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
    } catch (error) {
      console.log(error)
    }
  }, [dispatch])

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp])
  

  

  if (loading) return <LoadingComponent message='Loading...' />
  
  return (
    <>
    <ToastContainer position="bottom-right" theme="colored" hideProgressBar />
      <CssBaseline />
      <Header />
      <Container maxWidth={false} >
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path={'/login'} component={Login} />
          <PrivateRoute roles={['Admin User', 'Normal User']} path='/admin' component={Admin} />
          <PrivateRoute roles={['Admin User', 'Normal User']} path='/register' component={Register} />
        </Switch>
        <Quotes />
      </Container>

      <Footer />  
      
    </>
  );
}

export default App;
