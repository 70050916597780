import { Box, Button, Card, CardContent, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import agent from "../api/agent";
import LoadingComponent from "../layout/LoadingComponent";
import { InteractionReference } from "../models/interactionReference";
import ViewReference from "./ViewReference";
import { PrintButton } from "./print-button";

interface Props {
    id: number | undefined,
    interactingDrug: string | undefined,
    userType: string
}

interface Interactions {
    antifungal: string,
    basicDetailedInformation: string,
    id: number,
    interactingDrug: string,
    proDetailedInformation: string,
    severity: number,
    drugInteractionReferences: InteractionReference[],
    severityAsString: string

}


export default function ReactionInfo(props: Props) {
    const [interactions, setInteractions] = useState<Interactions[] | null>(null);
    const [thisId] = useState<number | null>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //setThisId(props.id)
        if(props.id !== undefined) {
          agent.drugInteractions.details(props.id!)
        .then((response) => {
            setInteractions(response);
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false))  
        } else {
            setLoading(false)
        }
    }, [props.id, thisId])

    //Show corresponding name of severity assigned to that code
    function showSeverity(code: number){
        if(code === 1){
            return 'Unlikely'
        }
        if(code === 2) {
            return 'Mild'
        }
        if(code === 3) {
            return 'Moderate'
        }
        if(code === 4) {
            return 'Severe'
        }
    }
    //Set background colour of card depending on severity
    function setBackground(code: number){
        if(code === 1){
            return '#d1ecf1'
        }
        if(code === 2) {
            return '#d4edda'
        }
        if(code === 3) {
            return '#fff3cd'
        }
        if(code === 4) {
            return '#f8d7da'
        }
    }
    // compare function to sort interactions array with most severe first
    function compare( a:Interactions, b: Interactions ) {
        if ( a.severity < b.severity ){
          return 1;
        }
        if ( a.severity > b.severity ){
          return -1;
        }
        return 0;
    }

    interactions?.sort(compare)
    
    if (loading) return <LoadingComponent message='Loading...' />
    return (
        <div id="content">
            <Box>
                {interactions !== null &&
                <Box display='flex' justifyContent='center'>
                    <Typography variant='h3' color='text.secondary'>Interactions for {props.interactingDrug}</Typography>
                    <PrintButton />
                </Box>}
                {interactions?.map((interaction) => (
                <Card key={interaction.id} sx={{margin:'10px', backgroundColor: setBackground(interaction.severity)}} >
                    <CardContent>
                        <Typography variant="h6" component='div'>
                            {showSeverity(interaction.severity)}
                            </Typography>
                        <Typography variant="h5" component='div'>{interaction.antifungal}</Typography>
                        {props.userType === 'Patient' &&
                        <Typography variant='body2' color='text.secondary'>
                            <div 
                                dangerouslySetInnerHTML={{__html: interaction.basicDetailedInformation}} 
                            />
                        </Typography>}
                        {props.userType === 'Clinician' &&
                        <Typography variant='body2' color='text.secondary'>
                        <div 
                                dangerouslySetInnerHTML={{__html: interaction.proDetailedInformation}} 
                            />
                        </Typography>}
                        {interaction.drugInteractionReferences.length > 0 &&
                        <ViewReference interaction={interaction} references={interaction.drugInteractionReferences}  />}
                    </CardContent>
                </Card>  
                ))}
            </Box>
        </div>
    )
}