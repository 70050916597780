import React from 'react';
import ReactDOM from 'react-dom';
import './app/layout/styles.css';
import App from './app/layout/App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store } from './app/store/configStore';
import { StoreProvider } from './app/context/PageContext';
import { Provider } from 'react-redux';
import { initGA } from './helpers/analytics';


export const history = createBrowserHistory();
initGA()

ReactDOM.render(
<React.StrictMode>
  <Router history={history}>
    <Provider store={store}>
      <StoreProvider store={store}>
          <App />
      </StoreProvider>
    </Provider>
  </Router>
  </React.StrictMode>,
  document.getElementById('root')
  );
  
