import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import agent from '../../api/agent';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Props {
  getTemplates: () => void;
}

export default function AddTemplateDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTemplate, setNewTemplate] = useState('');
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
}

  const { handleSubmit } = useForm({
    mode:'all',
});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmitData() {
    setLoading(true);
    let formData = new FormData();
    formData.append('message', newTemplate);
    agent.Templates.create(formData, config)
    .then((response) =>  {
      toast.success(response);
      setNewTemplate('');
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      props.getTemplates();
      setLoading(false);
    })
    handleClose();
  }

  function handleChangeTemplate( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewTemplate(event.target.value);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add new Template
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new template below
          </DialogContentText>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="template message"
            type="text"
            fullWidth
            variant="standard"
            value={newTemplate}
            onChange={handleChangeTemplate}
            />
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' loading={loading} color='success' sx={{float:'right'}}>Submit</LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}