import { Card, CardContent, Grid, Typography } from "@mui/material";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';



// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     fontSize: '1.25rem',
//     color: theme.palette.text.secondary,
//     height:'200px'
//   }));

export default function Quotes() {
    

    let arr: any = [];
    while(arr.length < 3){
        let r = Math.floor(Math.random() * 17) + 1;
        if(arr.indexOf(r) === -1) arr.push(r);
    } 
    return (
        <>
        <hr />
        <Grid container spacing={2} direction="row"  alignItems="center" justifyContent="center" component='div'>
            <Grid item xs={12} md={4}>
                <Card style={{minHeight:'15vw'}}>
                    <CardContent>
                        <LocalHospitalIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />

                        <Typography variant='h6' component='div'>{quotes[arr[0]]}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card style={{minHeight:'15vw'}}>
                    <CardContent>
                        <LocalHospitalIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />

                        <Typography variant='h6' component='div'>{quotes[arr[1]]}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card style={{minHeight:'15vw'}}>
                    <CardContent>
                        <LocalHospitalIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />

                        <Typography variant='h6' component='div'>{quotes[arr[2]]}</Typography>
                    </CardContent>
                </Card>
            </Grid>
                
                {/* <Item>
                    <LocalHospitalIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />
                    {quotes[arr[0]]}
                </Item>
            </Grid>
            <Grid item xs={12} md={4}>
                <Item>
                    <VaccinesIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />
                    {quotes[arr[1]]}
                </Item>
            </Grid>
            <Grid item xs={12} md={4}>
                <Item>
                    <LocalHospitalIcon color='primary' sx={{ fontSize: 80, verticalAlign:"middle" }} />
                    {quotes[arr[2]]}
                </Item> */}
            {/* </Grid> */}
        </Grid>
        </>
    )
}

const quotes=[
    "As many people die of Fungal Infection as TB and many more than MALARIA",
    "Antifungal drugs are unaffordable for most people",
    "Pneumocystis pneumonia is one of the commonest killers of children with AIDS",
    "In Botswana, fluconazole availability only through Pfizer’s DDP donation program",
    "Itraconazole is not available in Senegal, Gambia, Burundi, Ukraine and Eritrea, and probably some other countries",
    "Amphotericin B is not available in Indonesia and 600,000 days of therapy are estimated to be needed",
    "Itraconazole 100mg caps cost $4.8 in Nigeria and $2.9 in South Africa",
    "Itraconazole 100mg caps cost $3.8 in Colombia $1.8 in Uruguay",
    "In Thailand, about 9,000 cases of fungal keratitis require topical natamycin 5% ophthalmic solution",
    "The itraconazole market in Brazil is estimated to be about 66 million capsules annually",
    "To prevent fungal infections in high risk leukaemia patients, itraconazole solution market is 5 million days",
    "Thailand has no flucytosine, but 750,000 tablets are needed per year",
    "Drug registration processes are simple and quick in DRC, Burkina Faso, Central African Republic and Niger",
    "The Nigerian market for voriconazole for invasive and chronic pulmonary aspergillosis is nearly 10 million days of therapy",
    "Flucytosine is not available in Africa, except for some clinical trials. It has been on the WHO EML since 2013",
    "In Lesotho, fluconazole availability only through Pfizer’s DDP donation program",
    "Flucytosine costs $250 per day in Colombia and $279 in Australia",
    "There are as many cases and more deaths from histoplasmosis in AIDS compared to TB in Latin America"
]

