import { Button } from '@mui/material';
import { CSVLink } from 'react-csv'
import { ReferenceReport } from '../../../models/report';

export const ExportReactCSV = ({ csvData, fileName }: { csvData: ReferenceReport[]; fileName: string }) => {
    const data = [
        ["Interacting Drug", "Description", "Reference URL"],
    ];
    csvData.forEach((element) => {
        data.push([element.interactingDrug.genericName, element.description, element.url]);
    });

    return (
        <>
            <Button>
                <CSVLink data={data} filename={fileName} target="_blank">Download </CSVLink>
            </Button>
        </>
        
    )
}