import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import agent from "../../../api/agent";
import { Antifungal } from "../../../models/antifungal";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAFDialog from "./AddAFDialog";
import { toast } from "react-toastify";
import { LoadingButton } from '@mui/lab';

export default function AntifungalAdmin() {
    const [antifungals, setAntifungals] = useState<Antifungal[] | null>(null);
    const [loading, setLoading] = useState(false);

    function getAntifungals() {
        agent.antifungalAgent.list()
        .then((response) => {
            setAntifungals(response)
        })
    }

    useEffect(() => {
      getAntifungals();
    }, [])

    function deleteAntifungal(id: number) {
        setLoading(true);
        agent.antifungalAgent.remove(id)
        .then((response) => {   
            toast.success('Item deleted');
        })
        .catch((error: any) => console.log(error))
        .finally(() => {
            getAntifungals();
            setLoading(false);
        });
    }
    

    return (
        <>
            <AddAFDialog getAntifungals={getAntifungals}/>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{fontSize:'20px'}}>Antifungal ID</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Antifungal</TableCell>
                        <TableCell sx={{fontSize:'20px'}} align="center">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {antifungals?.map((antifungal: Antifungal) => (
                        <TableRow
                        key={antifungal.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {antifungal.id}
                        </TableCell>
                        <TableCell align="center">{antifungal.name}</TableCell>
                        <TableCell align="center">
                            <LoadingButton loading={loading} onClick={() => deleteAntifungal(antifungal.id)}>
                                <DeleteIcon color="error"  />
                            </LoadingButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}