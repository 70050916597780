
import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, Button, Badge, Divider } from "@mui/material";
import { useState } from "react";
import { InteractionReference } from "../models/interactionReference";
import { Interactions } from "../models/interactions";


interface Props {
  references: InteractionReference[];
  interaction: Interactions;
}

export default function AddReferenceDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        View reference details
        <Badge 
          badgeContent={`${props.references.length}`} 
          color='primary'
          sx={{marginLeft: '20px'}}>
        </Badge>
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>References for {props.interaction.antifungal} & {props.interaction.interactingDrug}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.references.map((reference, index) => (
              <Box key={index}>
                {index+1} - {reference.description} - <a href={reference.referenceUrl}>{reference.referenceUrl}</a>
                <Divider />
              </Box>
            ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      </>
  );
}