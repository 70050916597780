import { Grid, Button, Paper, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AntifungalAdmin from "../../app/components/admin/antifungal/AntifungalAdmin";
import InfoBoxesAdmin from "../../app/components/admin/infoBoxes/InfoBoxesAdmin";
import InteractingDrugAdmin from "../../app/components/admin/interactingDrugs/InteractingDrugAdmin";
import InteractionsAdmin from "../../app/components/admin/interactions/InteractionsAdmin";
import Reports from "../../app/components/admin/reports/reports";
import Users from "../../app/components/admin/users/Users";
import PageSelector from "../../app/components/PageSelector";
import Selector from "../../app/components/Selector";
import { usePageContext } from "../../app/context/PageContext";
import { useAppDispatch } from "../../app/store/configStore";
import { signOut } from "../account/accountSlice";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function Admin() {
    const {page} = usePageContext();
    const dispatch = useAppDispatch();
    return (
        <>
            <Grid 
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="center"
            >
                <Grid item xs={6} md={6}>
                    <Typography variant="h2" textAlign="right">Admin Area </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button sx={{float:'right'}} variant='contained' onClick={() => {
                    dispatch(signOut());
                    }}>
                        Logout
                    </Button>
                    <Button variant='contained' sx={{float:'right', color: 'white', textDecoration: 'none', marginRight: '5px'}}>
                        <Link to='/register' >
                            <p style={{color: 'white', margin: 0}}>New user</p>
                        </Link>
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} sx={{paddingTop: '20px'}}>
                    <PageSelector />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    <Item style={{ minHeight: '70vh' }}>
                        <Typography variant='h3'>{page}</Typography> 
                        {page === 'Antifungals' &&
                        <AntifungalAdmin />
                        } 
                        {page === 'Interacting Drugs' &&
                        <InteractingDrugAdmin />
                        }
                        {page === 'Info Boxes' &&
                        <InfoBoxesAdmin />
                        }
                        {page === 'Drug Interactions' &&
                        <InteractionsAdmin />
                        }
                        {page === 'Users' &&
                        <Users />
                        }
                        {page === 'Reports' &&
                        <Reports />
                        }
                    </Item>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Item style={{ minHeight: '70vh' }}>
                        <Selector />
                    </Item>
                </Grid>
            </Grid>
        </>
    )
}