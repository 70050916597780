import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import agent from '../../../api/agent';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import LoadingComponent from '../../../layout/LoadingComponent';

interface Props {
  getAntifungals: () => void
}

export default function AddAFDialog({getAntifungals}: Props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAntifungal, setNewAntifungal] = useState('');
  const loadingText = ['Adding Antifungal...', 'Initialising Interactiions...'];
  const [index, setIndex] = useState(0);
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
  }

  const { handleSubmit } = useForm({
    mode:'all',
});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmitData() {
    setLoading(true);
    let formData = new FormData();
    formData.append('name', newAntifungal);
    agent.antifungalAgent.create(formData, config)
    .then((response) =>  {
      toast.success('Item Added');
    })
    .catch((error: any) => {
      toast.warn(`You can't do that - ${error.response.statusText}`);
    })
    .finally(() => {
      handleClose();
      getAntifungals();
      setLoading(false);
    })
  }

  useEffect(() => {
    const timer = () => {
      setIndex(prevIndex => {
        if(prevIndex === loadingText.length - 1){
          return 0;
        } 
        return prevIndex + 1;
      })
    };
    setInterval(timer, 2500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeAntifungal( event: React.ChangeEvent<HTMLInputElement>) : void {
    setNewAntifungal(event.target.value);
  }
  if (loading) {
    return <LoadingComponent message={loadingText[index]} />
  } else {

  
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add new Antifungal
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new antifungal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the new antifungal below.
          </DialogContentText>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Antifungal Name"
            type="text"
            fullWidth
            variant="standard"
            value={newAntifungal}
            onChange={handleChangeAntifungal}
            />
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' color='success' sx={{float:'right'}}>Submit</LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
  }
}